import React, { useEffect, useState } from "react";
import { JobPendingComponentProps } from "../../../models/interfaces";
import JobConfigView from "../../../components/JobConfigView";
import { TableColumn } from "../../../components/Table";
import { JobType } from "../../../constants/JobTypes";
import axiosInstance from "../../../utils/axiosInstance";

const columns: TableColumn[] = [
  { key: "name", label: "Name" },
  { key: "jobType", label: "Job Type" },
  { key: "sourceType", label: "Source Type" },
  { key: "jobVersion", label: "Job Version" },
  { key: "confidence", label: "Confidence" },
  { key: "status", label: "Status" },
];

const nbaBreadCrumbs = [
  {
    name: "Home",
    url: "/",
  },
  {
    name: "Buying",
    url: "/optimization/next-best-action",
  },
  {
    name: "Next Best Action",
    url: "/optimization/next-best-action",
  },
];

const NextBestAction: React.FC = () => {
  const [jobConfigList, setJobConfigList] = useState([]);
  const [jobRunList, setJobRunList] = useState([]);
  useEffect(() => {
    axiosInstance
      .get("/api/jobConfig/jobType/NEXT_BEST_ACTION")
      .then((jobConfigListResponse) => {
        setJobConfigList(jobConfigListResponse.data.items);
      });
    axiosInstance
      .get("/api/jobRun/jobType/NEXT_BEST_ACTION")
      .then((jobRunListResponse) => {
        setJobRunList(jobRunListResponse.data.items);
      });
  }, []);

  return (
    <>
      <JobConfigView
        jobConfigList={jobConfigList}
        jobRunList={jobRunList}
        breadCrumbs={nbaBreadCrumbs}
        tableColumns={columns}
        jobType={JobType.NextBestAction}
      />
    </>
  );
};

export default NextBestAction;
