import React, { useEffect, useState } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import NavBar from "../../../../components/NavBar";
import DynamicForm, {
  ExtendedFieldConfig,
} from "../../../../components/DynamicForm";
import { z } from "zod";
import PieChart from "../../../../components/PieChart";
import WaterFallChart from "../../../../components/WaterFallChart";
import axiosInstance from "../../../../utils/axiosInstance";
import LineChart, {
  LineChartProps,
  LineData,
} from "../../../../components/LineChart";
import Table, { TableColumn, TableRecord } from "../../../../components/Table";
import Maximize from "../../../../components/Maximize";
import GradientStackedAreaChart from "../../../../components/GradientStackedAreaChart";
import { CONTRIBUTION_PERC_API, MMM_INPUT_DATA, OPTIMIZE_SPEND_API } from "../../../../constants/API";
import { Maximize2 } from "react-feather";
import HeatMap from "../../../../components/HeatMap";
import ScenarioTable from "../../../../components/ScenarioTable";
import MultiBarLineChartECharts from "../../../../components/BarChart";
import PieChartECharts from "../../../../components/PieChart";

const initialBreadcrumbs = [
  {
    name: "Home",
    url: "/",
  },
  {
    name: "Planning",
    url: "/planning/marketing-mix-model",
  },
  {
    name: "Marketing Mix Model",
    url: "/planning/marketing-mix-model",
  },
];

interface MMMDashboardProps {
  details: any;
  result: any;
}

const generateSaturationCharts = (response: any) => {
  const colors = [
    "rgba(75, 192, 192, 1)",
    "rgba(255, 99, 132, 1)",
    "rgba(54, 162, 235, 1)",
    "rgba(255, 206, 86, 1)",
    "rgba(153, 102, 255, 1)",
    "rgba(255, 159, 64, 1)",
  ];

  const charts: any[] = [];
  const allKeys = Object.keys(response);

  allKeys.forEach((key, index) => {
    if (key.startsWith("Saturation_")) {
      const data = response[key];
      const datasetName = key
        .replaceAll("Saturation_", "")
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
        .replaceAll(/_/g, " ");
      const color = colors[index % colors.length];
      const labels = data.map((item: any) => item.Spends);
      const lineData = data.map((item: any) => {
        return item[key.replaceAll("Saturation_", "")];
      });
      const chartProps: LineChartProps = {
        labels,
        dataSets: [
          {
            name: datasetName,
            data: lineData,
            borderColor: color,
            backgroundColor: color.replaceAll("1)", "0.3)"),
          },
        ],
        xAxisTitle: "Spends",
        yAxisTitle: datasetName,
      };

      charts.push(
        <div key={`${key}-${index}`} className="" style={{ width: "calc(50% - 8px)" }}>
          <div className="w-full">
            <Maximize title={datasetName}>
              <LineChart key={key} {...chartProps} />
            </Maximize>
          </div>
        </div>
      );
    }
  });

  return charts;
};

function normalizeData(data: any) {
  const min = Math.min(...data);
  const max = Math.max(...data);
  return data.map((value: any) => (value - min) / (max - min));
}

let validationCallback: any = (data: any, setError: any) => {
  console.log("validation callback", data);
}

const multiColinearityColumn: TableColumn[] = [
  { key: "variable", label: "Variable" },
  { key: "value", label: "Value" },
];

let scenarioData: any = {
  'Scenario 1': {
    "variables": { "Total Budget": "", "Weeks": "" },
    "yearlySpendChartData": [],
    "contributionChartData": [],
    "labels": []
  },
  'Scenario 2': {
    "variables": { "Total Budget": "", "Weeks": "" },
    "yearlySpendChartData": [],
    "contributionChartData": [],
    "labels": []
  },
  'Scenario 3': {
    "variables": { "Total Budget": "", "Weeks": "" },
    "yearlySpendChartData": [],
    "contributionChartData": [],
    "labels": []
  },
  'Scenario 4': {
    "variables": { "Total Budget": "", "Weeks": "" },
    "yearlySpendChartData": [],
    "contributionChartData": [],
    "labels": []
  },
  'Scenario 5': {
    "variables": { "Total Budget": "", "Weeks": "" },
    "yearlySpendChartData": [],
    "contributionChartData": [],
    "labels": []
  }
};

const MMMDashboard: React.FC = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const name = params.get("name") ? decodeURIComponent(params.get("name") as string) : "";

  let [breadcrumbs, setBreadcrumbs] = useState(initialBreadcrumbs);
  const { id } = useParams<{ id: string }>(); // Extract UUID from URL

  useEffect(() => {
    setBreadcrumbs((prevBreadcrumbs: any) => {
      if (!prevBreadcrumbs.some((breadcrumb: any) => breadcrumb.name === name)) {
        return [
          ...prevBreadcrumbs,
          { name: name, url: `/planning/marketing-mix-model/${id}` },
        ];
      }
      return prevBreadcrumbs;
    });
  }, [id]);

  const [contributionChartData, setContributionChartData] = useState([]);
  const [contributionChartLabel, setContributionChartLabel] = useState([]);

  const [saturationCharts, setSaturationCharts] = useState([]);

  const [yearlyOptimizedSpendChartData, setYearlyOptimizedSpendChartData] =
    useState([]);
  const [yearlyOptimizedSpendChartLabel, setYearlyOptimizedSpendChartLabel] =
    useState([]);

  const [roiFinalData, setRoiFinalData] = useState<any[]>([]);
  const [roiLabels, setRoiLabels] = useState<string[]>([]);

  const [actualPredictedLabels, setActualPredictedLabels] = useState([]);
  const [actualSales, setActualSales] = useState([]);
  const [predictedSales, setPredictedSales] = useState([]);

  const [y1, setY1] = useState([]);
  const [y2, setY2] = useState([]);
  const [correlationLabels, setCorrelationLabels] = useState([]);

  const [dateVariables, setDateVariables] = useState<string[]>([]);
  const [mediaVariables, setMediaVariables] = useState<string[]>([]);
  const [targetVariables, setTargetVariables] = useState<string[]>([]);

  const [waterFallChartLabel, setWaterFallChartLabel] = useState([]);
  const [waterFallChartData, setWaterFallChartData] = useState([]);

  const [salesDecompositionLabel, setSalesDecompositionLabel] = useState([]);
  const [salesDecompositionData, setSalesDecompositionData] = useState([]);

  const [historicSpendContributionLabel, setHistoricSpendContributionLabel] = useState<any>([]);
  const [historicSpendContributionData, setHistoricSpendContributionData] = useState<any>([]);

  const [elasticityColumn, setElasticityColumn] = useState<TableColumn[]>([]);
  const [elasticityRecord, setElasticityRecord] = useState<TableRecord[]>([]);

  const [seasonDataSet, setSeasonDataSet] = useState<LineData[]>([]);
  const [trendDataSet, setTrendDataSet] = useState<LineData[]>([]);
  const [seasonLabel, setSeasonLabel] = useState([]);
  const [modelAccuracy, setModelAccuracy] = useState([]);

  const [heatMapData, setHeatMapData] = useState<any>(undefined);

  const [multiColinearityRecords, setMultiColinearityRecords] = useState<
    TableRecord[]
  >([]);

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    axiosInstance.get(`/api/jobConfig/${id}`).then((response) => {
      const mediaVariables = response.data.dataMappingConfig.mappings.mmm.columnMapping.media_variables;
      const targetVariables = response.data.dataMappingConfig.mappings.mmm.columnMapping.target_variables;
      const dateVariables = response.data.dataMappingConfig.mappings.mmm.columnMapping.date_variables;


      setDateVariables(dateVariables);
      setMediaVariables(mediaVariables);
      setTargetVariables(targetVariables);

      fetchCorrelationData(mediaVariables[0], targetVariables[0], dateVariables[0]);


      axiosInstance.post(CONTRIBUTION_PERC_API, {
        spendColumns: mediaVariables,
        jobConfigId: id,
        tenantId: '00000000-0000-0000-0000-000000000000'
      }).then((response) => {
        const contributionData = response.data.totalContribution;

        // Extract labels and data for pie chart
        const labels: string[] = Object.keys(contributionData);
        const data: string[] = Object.values(contributionData);

        setHistoricSpendContributionLabel(labels);
        setHistoricSpendContributionData(data);
      });
      validationCallback = (data: any, setError: any) => {
        let totalContribution = 100;
        mediaVariables.forEach((item: string) => {
          if (data[item] !== "") {
            totalContribution -= Number.parseFloat(data[item]);
          }
        });

        if (totalContribution <= 0) {
          setError("form", {
            type: "manual",
            message: "Total Contribution addition should not be more than 100",
          });
          return false;
        }
        return true;
      }
      let groupCounter = 2; // Start from "row2"

      scenarioData = {
        'Scenario 1': {
          "variables": { "Total Budget": "", "Weeks": "" },
          "yearlySpendChartData": [],
          "contributionChartData": [],
          "labels": []
        },
        'Scenario 2': {
          "variables": { "Total Budget": "", "Weeks": "" },
          "yearlySpendChartData": [],
          "contributionChartData": [],
          "labels": []
        },
        'Scenario 3': {
          "variables": { "Total Budget": "", "Weeks": "" },
          "yearlySpendChartData": [],
          "contributionChartData": [],
          "labels": []
        },
        'Scenario 4': {
          "variables": { "Total Budget": "", "Weeks": "" },
          "yearlySpendChartData": [],
          "contributionChartData": [],
          "labels": []
        },
        'Scenario 5': {
          "variables": { "Total Budget": "", "Weeks": "" },
          "yearlySpendChartData": [],
          "contributionChartData": [],
          "labels": []
        }
      };


      axiosInstance.get(`/api/mmm/output/${id}`).then((response) => {
        let outputJson = response.data.result;
        if (typeof outputJson === "string") {
          try {
            outputJson = JSON.parse(outputJson);
          } catch (error) {
            console.error("Failed to parse JSON string:", error);
          }
        }

        console.log("outputJson", outputJson);

        if (outputJson.ROI_Final) {
          setRoiFinalData(outputJson.ROI_Final);
          setRoiLabels(outputJson.ROI_Final.map((item: any) => item.MediaChannel));
        }
        setModelAccuracy(outputJson["ModelAccuracy"])
        const seasonalityData = outputJson["Seasonality"];

        scenarioData = {
          'Scenario 1': {
            "variables": { "Total Budget": "", "Weeks": "" },
            "yearlySpendChartData": [],
            "contributionChartData": [],
            "labels": []
          },
          'Scenario 2': {
            "variables": { "Total Budget": "", "Weeks": "" },
            "yearlySpendChartData": [],
            "contributionChartData": [],
            "labels": []
          },
          'Scenario 3': {
            "variables": { "Total Budget": "", "Weeks": "" },
            "yearlySpendChartData": [],
            "contributionChartData": [],
            "labels": []
          },
          'Scenario 4': {
            "variables": { "Total Budget": "", "Weeks": "" },
            "yearlySpendChartData": [],
            "contributionChartData": [],
            "labels": []
          },
          'Scenario 5': {
            "variables": { "Total Budget": "", "Weeks": "" },
            "yearlySpendChartData": [],
            "contributionChartData": [],
            "labels": []
          }
        };
        outputJson["AllScenarios_BudgetAndMediaVariables"] && outputJson["AllScenarios_BudgetAndMediaVariables"].forEach((scenario: any) => {
          // Get the scenario key (e.g., "Scenario 1")
          const scenarioKey = scenario.Scenario;

          // Ensure scenarioData has a 'variables' object for each scenario
          if (!scenarioData[scenarioKey]) {
            scenarioData[scenarioKey] = {
              variables: {},
              yearlySpendChartData: [],
              labels: [],
            };
          }

          // Update Total Budget and Weeks for this scenario
          scenarioData[scenarioKey].variables['Total Budget'] = scenario['Total Budget'];
          scenarioData[scenarioKey].variables['Weeks'] = scenario['Weeks'];

          // Loop through all keys in the scenario object to find media variables
          Object.keys(scenario).forEach((key) => {
            if (!['Scenario', 'Total Budget', 'Weeks'].includes(key)) {

              // Add the key to the mediaVariables array if it's not already there
              if (!mediaVariables.includes(key)) {
                mediaVariables.push(key);
              }

              if (scenario["Scenario"] === 'Scenario 1') {
                scenarioData[scenarioKey].variables[key] = '0';
                scenarioData[scenarioKey].yearlySpendChartData.push(outputJson[`Final_Optimizer`].filter((item: any) => {
                  return item.Media === key
                })[0]['Yearly_Optimize_Spend']);

                // Optionally, you can update yearlySpendChartData and labels here, if necessary
                const otherSalesContribution = outputJson.Contributions_chart
                  .filter((item: any) => item.Variables === 'Base' || item.Variables === 'Seasonality')
                  .reduce((sum: any, item: any) => sum + item.Contributions, 0); // Corrected to 'Contributions'

                scenarioData[scenarioKey].predictedSales = outputJson[`Final_Optimizer`].reduce((sum: any, item: any) => sum + item.Contribution, 0) + otherSalesContribution;
                scenarioData[scenarioKey].labels.push(key);
              } else {
                scenarioData[scenarioKey].variables[key] = scenario[key];

                scenarioData[scenarioKey].yearlySpendChartData.push(outputJson[`${scenario["Scenario"]}_Final_Optimizer`].filter((item: any) => {
                  return item.Media === key
                })[0]['Yearly_Optimize_Spend']);

                // Optionally, you can update yearlySpendChartData and labels here, if necessary
                const otherSalesContribution = outputJson.Contributions_chart
                  .filter((item: any) => item.Variables === 'Base' || item.Variables === 'Seasonality')
                  .reduce((sum: any, item: any) => sum + item.Contributions, 0); // Corrected to 'Contributions'

                scenarioData[scenarioKey].predictedSales = outputJson[`${scenario["Scenario"]}_Final_Optimizer`].reduce((sum: any, item: any) => sum + item.Contribution, 0) + otherSalesContribution;
                scenarioData[scenarioKey].labels.push(key);
              }
            }
          });
        });


        !outputJson["AllScenarios_BudgetAndMediaVariables"] && [1, 2, 3, 4, 5].forEach((scenario: any) => {

          // Update Total Budget and Weeks for this scenario
          scenarioData[`Scenario ${scenario}`].variables['Total Budget'] = '20000000';
          scenarioData[`Scenario ${scenario}`].variables['Weeks'] = '52';

          outputJson['Final_Optimizer'].forEach((element: any) => {
            scenarioData[`Scenario ${scenario}`].variables[element.Media] = '0';
            scenarioData[`Scenario ${scenario}`].yearlySpendChartData.push(element.Yearly_Optimize_Spend);
            scenarioData[`Scenario ${scenario}`].labels.push(element.Media);
          });

          const otherSalesContribution = outputJson.Contributions_chart
            .filter((item: any) => item.Variables === 'Base' || item.Variables === 'Seasonality')
            .reduce((sum: any, item: any) => sum + item.Contributions, 0); // Corrected to 'Contributions'

          scenarioData[`Scenario ${scenario}`].predictedSales = outputJson[`Final_Optimizer`].reduce((sum: any, item: any) => {
            const contribution = typeof item.Contribution === 'string' ? parseFloat(item.Contribution) : item.Contribution;
            return sum + (isNaN(contribution) ? 0 : contribution);
          }, 0) + otherSalesContribution;

          // // Loop through all keys in the scenario object to find media variables
          // Object.keys(scenario).forEach((key) => {
          //   if (!['Scenario', 'Total Budget', 'Weeks'].includes(key)) {
          //     // If the key is a media variable, update the scenarioData for media variables
          //     scenarioData[scenarioKey].variables[key] = scenario[key];

          //     // Add the key to the mediaVariables array if it's not already there
          //     if (!mediaVariables.includes(key)) {
          //       mediaVariables.push(key);
          //     }

          //     // Optionally, you can update yearlySpendChartData and labels here, if necessary
          //     scenarioData[scenarioKey].yearlySpendChartData.push(outputJson[`${scenario["Scenario"]}_Final_Optimizer`].filter((item: any) => {
          //       console.log(item);
          //       return item.Media === key
          //     })[0]['Yearly_Optimize_Spend']);
          //     scenarioData[scenarioKey].labels.push(key);
          //   }
          // });
        });


        if (seasonalityData) {
          const labels = seasonalityData.map(
            (item: any) => item[dateVariables[0]]
          );
          const seasonData = seasonalityData.map((item: any) => parseFloat(item.Season.toFixed(2)));
          const trendData = seasonalityData.map((item: any) => parseFloat(item.Trend.toFixed(2)));

          setSeasonLabel(labels);

          setSeasonDataSet([
            {
              name: "Season",
              data: seasonData,
              borderColor: "rgba(75, 192, 192, 1)",
              backgroundColor: "rgba(75, 192, 192, 0.3)",
            },
          ]);

          setTrendDataSet([
            {
              name: "Trend",
              data: trendData,
              borderColor: "rgba(255, 99, 132, 1)",
              backgroundColor: "rgba(255, 99, 132, 0.3)",
            },
          ]);
        }

        const correlationMatrix = outputJson["CorrelationMatrix"];
        if (correlationMatrix) {
          // Extract xLabels and yLabels (assuming all keys are the same across the objects)
          const targetVariableKey = targetVariables[0];

          // Sort xLabels so that targetVariableKey comes first, and the rest are sorted alphabetically
          const xLabels = Object.keys(correlationMatrix[0]).sort((a, b) => {
            if (a === targetVariableKey) return -1; // Place targetVariableKey first
            if (b === targetVariableKey) return 1;  // Place targetVariableKey first
            return a.localeCompare(b); // Sort remaining items alphabetically
          });
          const yLabels = xLabels;  // Since it's a square matrix, x and y labels are the same

          const sortedMatrix = xLabels.map(label => {
            return correlationMatrix.find((row: { [x: string]: number; }) => row[label] === 1); // Find the row where the variable correlates with itself
          });
          // Create the zData matrix
          const zData = sortedMatrix.map((row: any) =>
            xLabels.map((x) => row[x])
          );

          // Setting the heatmap data
          setHeatMapData({
            xLabels,
            yLabels,
            zData,
          });
        }

        if (outputJson["VifFactors"]) {
          const records: TableRecord[] = [];
          outputJson["VifFactors"].map((item: { Variable: string, VIF: number }) => {
            records.push({
              variable: item.Variable.split('_')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' '),
              value: item.VIF,
            });
          });
          setMultiColinearityRecords(records);
        }

        setSalesDecompositionData(outputJson["Sales_Decomposition"]);
        setSalesDecompositionLabel(outputJson["Sales_Decomposition"].map((item: any, index: any) => index));
        const charts = generateSaturationCharts(outputJson);

        setSaturationCharts(charts as any);

        setContributionChartData(
          outputJson["Final_Optimizer"].map((item: any) =>
            Number.parseFloat(item.Contribution)
          )
        );
        setContributionChartLabel(
          outputJson["Final_Optimizer"].map((item: any) => item.Media)
        );

        setYearlyOptimizedSpendChartData(
          outputJson["Final_Optimizer"].map((item: any) =>
            Number.parseFloat(item.Yearly_Optimize_Spend)
          )
        );
        setYearlyOptimizedSpendChartLabel(
          outputJson["Final_Optimizer"].map((item: any) => item.Media)
        );

        setActualPredictedLabels(
          outputJson["Actual_vs_predicted"]
            .map((item: any) => {
              const dateKey = Object.keys(item).find(
                (key) => key.trim() === "Date"
              );
              if (dateKey) {
                return item[dateKey];
              }
              return null;
            })
            .filter((date: any) => date !== null)
        );
        setActualSales(
          outputJson["Actual_vs_predicted"].map((item: any) => item[targetVariables[0]])
        );
        setPredictedSales(
          outputJson["Actual_vs_predicted"].map((item: any) => item.prediction)
        );

        // Sort the array to prioritize "Seasonality" and "Base" by their Contributions values
        const sortedContributionsChart = outputJson.Contributions_chart.sort((a: any, b: any) => {
          // Check if both items are "Seasonality" or "Base"
          const isAImportant = a.Variables === "Seasonality" || a.Variables === "Base";
          const isBImportant = b.Variables === "Seasonality" || b.Variables === "Base";

          // Sort items with "Seasonality" or "Base" by Contributions
          if (isAImportant && isBImportant) {
            return b.Contributions - a.Contributions; // Descending order by Contributions
          }

          // Place "Seasonality" or "Base" items first
          if (isAImportant) return -1;
          if (isBImportant) return 1;

          return 0; // Keep original order for other items
        });

        // Set labels and data after sorting
        setWaterFallChartLabel(
          sortedContributionsChart.map((item: any) => item.Variables)
        );

        setWaterFallChartData(
          sortedContributionsChart.map((item: any) =>
            Number.parseInt(item.Contributions)
          )
        );

        setElasticityColumn(
          Object.keys(outputJson.Elasticity_Report[0]).sort((a, b) => {
            if (a === "MediaChannel") return -1; // Move "MediaChannel" to the first position
            if (b === "MediaChannel") return 1;
            return 0; // Maintain order for other keys
          }).map((key) => ({
            key: key.trim(),
            label: key.trim().replaceAll("_", " "),
          }))
        );
        setElasticityRecord(
          outputJson.Elasticity_Report
            // .filter((item: any) => item.MediaChannel !== 'Base' && item.MediaChannel !== 'Seasonality') // filter out "Base" and "Seasonality"
            .map((item: any) => {
              const record: TableRecord = {};
              Object.keys(item).forEach((key) => {
                // Replace underscores with spaces and capitalize each word for the key
                const formattedKey = key
                  .trim()
                  .split('_')
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(' ');

                // Apply the same transformation to the value if it's a string
                let formattedValue = item[key];
                if (typeof formattedValue === 'string') {
                  formattedValue = formattedValue
                    .trim()
                    .split('_')
                    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ');
                }

                record[formattedKey] = formattedValue;
              });
              return record;
            })
        );

      });
    });


  }, [id]);

  const effectShareValues = roiFinalData.map((item: any) => item.effect_share);
  const spendShareValues = roiFinalData.map((item: any) => item.spend_share);
  const roasValues = roiFinalData.map((item: any) => item.ROAS);

  const roiBarChartData = [
    {
      name: "Effect Share",
      values: effectShareValues,
      color: "rgba(54, 162, 235, 1)", // Teal-blue color
    },
    {
      name: "Spend Share",
      values: spendShareValues,
      color: "rgba(255, 159, 64, 1)", // Bright orange color
    }
  ];

  const roiLineChartData = [
    {
      name: "ROAS",
      values: roasValues,
      color: "rgba(54, 162, 235, 1)", // Teal-blue color
    },
  ];

  const runOptimizeMediaSpend = async (data: any) => {
    setLoading(true);
    data.jobConfigId = id;
    data.tenantId = '00000000-0000-0000-0000-000000000000';
    const response = await axiosInstance.post(OPTIMIZE_SPEND_API, data);
    let outputJson = response.data.result;
    if (typeof outputJson === "string") {
      try {
        outputJson = JSON.parse(outputJson);
      } catch (error) {
        console.error("Failed to parse JSON string:", error);
      }
    }
    setLoading(false);
    window.location.reload();
  }

  const waterfallData = {
    labels: waterFallChartLabel,
    datasets: [
      {
        label: "Contributions",
        data: waterFallChartData,
      },
    ],
  };


  const twoLineChartData: LineChartProps = {
    labels: actualPredictedLabels,
    dataSets: [
      {
        name: "Actual Sales",
        data: actualSales,
        borderColor: "#ff0000",
        backgroundColor: "rgba(255, 0, 0, 0.3)",
      },
      {
        name: "Predicted Sales",
        data: predictedSales,
        borderColor: "#0000ff",
        backgroundColor: "rgba(0, 0, 255, 0.3)",
      },
    ],
    xAxisTitle: "Days",
    yAxisTitle: "Sales",
  };

  const correlationChart: LineChartProps = {
    labels: correlationLabels,
    dataSets: [
      {
        name: "Media Variable",
        data: y1,
        borderColor: "#ff0000",
        backgroundColor: "rgba(255, 0, 0, 0.3)",
      },
      {
        name: "Target Variable",
        data: y2,
        borderColor: "#0000ff",
        backgroundColor: "rgba(0, 0, 255, 0.3)",
      },
    ],
    xAxisTitle: "Days",
    yAxisTitle: "Sales",
  };

  const onCorrelationMediaVariableChange = (data: any) => {
    console.log("selection change", data);
    fetchCorrelationData(data.target.value, targetVariables[0], dateVariables[0]);
  }

  const fetchCorrelationData = (y1: string, y2: string, x: string) => {
    axiosInstance.post(MMM_INPUT_DATA, {
      jobConfigId: id,
      tenantId: '00000000-0000-0000-0000-000000000000',
      y1,
      y2,
      x
    }).then((response) => {
      setCorrelationLabels(
        response.data["date"]
      );
      setY1(
        normalizeData(response.data["y1"])
      );
      setY2(
        normalizeData(response.data["y2"])
      );
    });
  }

  return (
    <NavBar>
      <div className="p-8 flex flex-col">
        <div className="flex justify-between">
          <div className="text-sm breadcrumbs self-end">
            <ul>
              {breadcrumbs.map((entity) => (
                <li key={entity.url}>
                  <Link to={entity.url} className="font-mono underline">
                    {entity.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="divider"></div>
        {trendDataSet.length > 0 && <div className="flex justify-between mx-8">
          <div style={{ width: "calc(50% - 8px)" }} className="card card-compact w-1/2 bg-base-100 shadow-xl border-2 mt-6 mr-6">
            <div className="card-body border-b-2 flex flex-row justify-between">
              <h2 className="card-title">Seasonality</h2>
              <button className="btn btn-square btn-sm">
                <Maximize2 width={18} />
              </button>
            </div>
            <div className="overflow-auto">
              <LineChart
                labels={seasonLabel}
                dataSets={seasonDataSet}
                yAxisTitle="Level Component For Sale"
                xAxisTitle="Date"
              />
            </div>
          </div>
          <div style={{ width: "calc(50% - 8px)" }} className="card card-compact bg-base-100 shadow-xl border-2 mt-6">
            <div className="card-body border-b-2 flex flex-row justify-between">
              <h2 className="card-title">Trend</h2>
              <button className="btn btn-square btn-sm">
                <Maximize2 width={18} />
              </button>
            </div>
            <div className="overflow-auto" >
              <LineChart
                labels={seasonLabel}
                dataSets={trendDataSet}
                xAxisTitle="Date"
                yAxisTitle="Level Component For Sale"
              />
            </div>
          </div>
        </div>}
        <div className="mx-8 flex justify-between">
          <div style={{ width: "calc(50% - 12px)" }}>
            <Maximize title="Correlation">
              <select className="select select-ghost select-sm" onChange={onCorrelationMediaVariableChange}>
                {mediaVariables.map((theme) => (
                  <option key={theme} value={theme}>{theme.toUpperCase()}</option>
                ))}
              </select>
              <LineChart {...correlationChart} />
            </Maximize>
          </div>
          <div style={{ width: "calc(50% - 12px)" }}>
            <Maximize title="Spend Contribution">
              <PieChartECharts
                data={historicSpendContributionData} // Numeric data for the pie chart
                labels={historicSpendContributionLabel} // Labels to be displayed in the pie chart
                showOutline={true}
              />
            </Maximize>
          </div>
        </div>
        {heatMapData && <div className="mx-8">
          <Maximize title={"Correlation Matrix"}>
            <HeatMap data={heatMapData} />
          </Maximize>
        </div>}
        {multiColinearityRecords.length > 0 && <div className="card card-compact w-auto bg-base-100 shadow-xl border-2 mt-6 mx-8">
          <div className="card-body border-b-2 flex flex-row justify-between">
            <h2 className="card-title">Multi Collinearity Check</h2>
            <button className="btn btn-square btn-sm">
              <Maximize2 width={18} />
            </button>
          </div>
          <div className="overflow-auto">
            <Table
              columns={multiColinearityColumn}
              records={multiColinearityRecords}
            />
          </div>
        </div>}
        <div className="mx-8">
          <Maximize title="Accumulated Waterfall Chart">
            <div className="w-full h-[700px]">
              <WaterFallChart
                data={waterFallChartData}
                labels={waterFallChartLabel}
              />
            </div>
          </Maximize>
        </div>
        <div className="divider"></div>
        <div className="mx-8">
          <Maximize title="Accumulated Waterfall Chart">
            <div className="w-full h-[700px]">
              <WaterFallChart
                data={waterFallChartData}
                labels={waterFallChartLabel}
                isBase100={true}
              />
            </div>
          </Maximize>
        </div>
        <div className="mx-8">
          <Maximize title="Actual Vs Predicted">
            <div className="w-full pt-4">
              {twoLineChartData && (<div>
                {modelAccuracy && modelAccuracy[0] && (<div className="flex justify-center">
                  <div className="mx-12">MAPE: {(modelAccuracy[0]["MAPE"] * 100 as number).toFixed(2)} %</div>
                  <div className="mr-12">R-Square: {(modelAccuracy[0]["R_Squared"] * 100 as number).toFixed(2)} %</div>
                </div>)}
                <LineChart {...twoLineChartData} />
              </div>)}
            </div>
          </Maximize>
        </div>
        <div className="mx-8">
          <Maximize title="Sales Decomposition">
            <div className="w-full pt-4">
              {salesDecompositionData.length && (
                <GradientStackedAreaChart
                  data={salesDecompositionData}
                  labels={salesDecompositionLabel}
                />
              )}
            </div>
          </Maximize>
        </div>

        <div className="mx-8">
          <Maximize title="Elasticity Report">
            <div className="w-full px-2">
              <Table columns={elasticityColumn} records={elasticityRecord} />
            </div>
          </Maximize>
        </div>
        <div>
          <h1 className="text-2xl font-bold text-center my-8">
            Saturation Curves
          </h1>
          <div className="flex flex-wrap mx-8 justify-between">
            {saturationCharts}
          </div>
        </div>
        {/* <div className="divider"></div>
        <div className="mx-8">
          <Maximize title="ROI Final">
            <MultiBarLineChartECharts
              labels={roiLabels}
              barDatasets={roiBarChartData}
              lineDatasets={roiLineChartData}
            />
          </Maximize>
        </div> */}
        <div className="divider"></div>
        <h1 className="text-2xl font-bold text-center mt-8 mb-2">
          Scenario Planner
        </h1>
        {contributionChartData.length > 0 && (<div className="w-full mt-2 mb-8 flex justify-center items-center">
          <ScenarioTable scenarioData={scenarioData} callback={runOptimizeMediaSpend} isLoading={isLoading} />
        </div>)}
        {/* <div className="flex justify-between mx-8">
          <div className="w-full">
            <Maximize title="Yearly Optimized Spend Chart">
              <div className="mt-8">
                <PieChart
                  data={yearlyOptimizedSpendChartData}
                  labels={yearlyOptimizedSpendChartLabel}
                />
              </div>
            </Maximize>
          </div>
        </div> */}
        {/* <div className="flex justify-between mx-8">
          <div className="w-full" >
            <Maximize title="Contribution Chart">
              <div className="mt-8">
                <PieChart
                  data={contributionChartData}
                  labels={contributionChartLabel}
                />
              </div>
            </Maximize>
          </div>
        </div> */}
      </div>
    </NavBar>
  );
};

export default MMMDashboard;
