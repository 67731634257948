export const LOCALE_LIST = [
    {
        "name": "English (US)",
        "key": 6,
        "gg_ads_key": 1006
    },
    {
        "name": "Catalan",
        "key": 1,
        "gg_ads_key": 1038
    },
    {
        "name": "Czech",
        "key": 2,
        "gg_ads_key": 1021
    },
    {
        "name": "Cebuano",
        "key": 56,
        "gg_ads_key": null
    },
    {
        "name": "Welsh",
        "key": 3,
        "gg_ads_key": 1060
    },
    {
        "name": "Danish",
        "key": 4,
        "gg_ads_key": 1009
    },
    {
        "name": "German",
        "key": 5,
        "gg_ads_key": 1001
    },
    {
        "name": "Basque",
        "key": 59,
        "gg_ads_key": 1063
    },
    {
        "name": "Spanish",
        "key": 23,
        "gg_ads_key": 1003
    },
    {
        "name": "Spanish (Spain)",
        "key": 7,
        "gg_ads_key": 1003
    },
    {
        "name": "Guarani",
        "key": 66,
        "gg_ads_key": 1071
    },
    {
        "name": "Finnish",
        "key": 8,
        "gg_ads_key": 1011
    },
    {
        "name": "French (France)",
        "key": 9,
        "gg_ads_key": 1002
    },
    {
        "name": "Galician",
        "key": 65,
        "gg_ads_key": 1070
    },
    {
        "name": "Hungarian",
        "key": 30,
        "gg_ads_key": 1024
    },
    {
        "name": "Italian",
        "key": 10,
        "gg_ads_key": 1004
    },
    {
        "name": "Japanese",
        "key": 11,
        "gg_ads_key": 1005
    },
    {
        "name": "Korean",
        "key": 12,
        "gg_ads_key": 1012
    },
    {
        "name": "Norwegian (bokmal)",
        "key": 13,
        "gg_ads_key": 1013
    },
    {
        "name": "Norwegian (nynorsk)",
        "key": 84,
        "gg_ads_key": 1061
    },
    {
        "name": "Dutch",
        "key": 14,
        "gg_ads_key": 1010
    },
    {
        "name": "Frisian",
        "key": 63,
        "gg_ads_key": 1067
    },
    {
        "name": "Polish",
        "key": 15,
        "gg_ads_key": 1030
    },
    {
        "name": "Portuguese (Brazil)",
        "key": 16,
        "gg_ads_key": 1016
    },
    {
        "name": "Portuguese (Portugal)",
        "key": 31,
        "gg_ads_key": 1014
    },
    {
        "name": "Romanian",
        "key": 32,
        "gg_ads_key": 1032
    },
    {
        "name": "Russian",
        "key": 17,
        "gg_ads_key": 1031
    },
    {
        "name": "Slovak",
        "key": 33,
        "gg_ads_key": 1033
    },
    {
        "name": "Slovenian",
        "key": 34,
        "gg_ads_key": 1034
    },
    {
        "name": "Swedish",
        "key": 18,
        "gg_ads_key": 1015
    },
    {
        "name": "Thai",
        "key": 35,
        "gg_ads_key": 1044
    },
    {
        "name": "Turkish",
        "key": 19,
        "gg_ads_key": 1037
    },
    {
        "name": "Northern Kurdish (Kurmanji)",
        "key": 76,
        "gg_ads_key": null
    },
    {
        "name": "Simplified Chinese (China)",
        "key": 20,
        "gg_ads_key": 1017
    },
    {
        "name": "Traditional Chinese (Hong Kong)",
        "key": 21,
        "gg_ads_key": 1150
    },
    {
        "name": "Traditional Chinese (Taiwan)",
        "key": 22,
        "gg_ads_key": 1018
    },
    {
        "name": "Afrikaans",
        "key": 36,
        "gg_ads_key": 1047
    },
    {
        "name": "Albanian",
        "key": 87,
        "gg_ads_key": 1125
    },
    {
        "name": "Armenian",
        "key": 68,
        "gg_ads_key": 1076
    },
    {
        "name": "Azerbaijani",
        "key": 53,
        "gg_ads_key": 1051
    },
    {
        "name": "Belarusian",
        "key": 54,
        "gg_ads_key": 1053
    },
    {
        "name": "Bengali",
        "key": 45,
        "gg_ads_key": 1056
    },
    {
        "name": "Bosnian",
        "key": 55,
        "gg_ads_key": 1075
    },
    {
        "name": "Bulgarian",
        "key": 37,
        "gg_ads_key": 1020
    },
    {
        "name": "Croatian",
        "key": 38,
        "gg_ads_key": 1039
    },
    {
        "name": "Flemish",
        "key": 83,
        "gg_ads_key": null
    },
    {
        "name": "English (UK)",
        "key": 24,
        "gg_ads_key": 1007
    },
    {
        "name": "Esperanto",
        "key": 57,
        "gg_ads_key": 1062
    },
    {
        "name": "Estonian",
        "key": 58,
        "gg_ads_key": 1043
    },
    {
        "name": "Faroese",
        "key": 62,
        "gg_ads_key": 1066
    },
    {
        "name": "French (Canada)",
        "key": 44,
        "gg_ads_key": 1002
    },
    {
        "name": "Georgian",
        "key": 72,
        "gg_ads_key": 1082
    },
    {
        "name": "Greek",
        "key": 39,
        "gg_ads_key": 1022
    },
    {
        "name": "Gujarati",
        "key": 67,
        "gg_ads_key": 1072
    },
    {
        "name": "Hindi",
        "key": 46,
        "gg_ads_key": 1023
    },
    {
        "name": "Icelandic",
        "key": 69,
        "gg_ads_key": 1026
    },
    {
        "name": "Indonesian",
        "key": 25,
        "gg_ads_key": 1025
    },
    {
        "name": "Irish",
        "key": 64,
        "gg_ads_key": 1068
    },
    {
        "name": "Javanese",
        "key": 71,
        "gg_ads_key": 1081
    },
    {
        "name": "Kannada",
        "key": 75,
        "gg_ads_key": 1086
    },
    {
        "name": "Kazakh",
        "key": 73,
        "gg_ads_key": 1083
    },
    {
        "name": "Latvian",
        "key": 78,
        "gg_ads_key": 1028
    },
    {
        "name": "Lithuanian",
        "key": 40,
        "gg_ads_key": 1029
    },
    {
        "name": "Macedonian",
        "key": 79,
        "gg_ads_key": 1097
    },
    {
        "name": "Malay",
        "key": 41,
        "gg_ads_key": 1102
    },
    {
        "name": "Marathi",
        "key": 81,
        "gg_ads_key": 1101
    },
    {
        "name": "Mongolian",
        "key": 80,
        "gg_ads_key": 1099
    },
    {
        "name": "Nepali",
        "key": 82,
        "gg_ads_key": 1106
    },
    {
        "name": "Punjabi",
        "key": 47,
        "gg_ads_key": 1110
    },
    {
        "name": "Serbian",
        "key": 42,
        "gg_ads_key": 1035
    },
    {
        "name": "Swahili",
        "key": 88,
        "gg_ads_key": 1129
    },
    {
        "name": "Filipino",
        "key": 26,
        "gg_ads_key": 1042
    },
    {
        "name": "Tamil",
        "key": 48,
        "gg_ads_key": 1130
    },
    {
        "name": "Telugu",
        "key": 49,
        "gg_ads_key": 1131
    },
    {
        "name": "Malayalam",
        "key": 50,
        "gg_ads_key": 1098
    },
    {
        "name": "Ukrainian",
        "key": 52,
        "gg_ads_key": 1036
    },
    {
        "name": "Uzbek",
        "key": 91,
        "gg_ads_key": 1141
    },
    {
        "name": "Vietnamese",
        "key": 27,
        "gg_ads_key": 1040
    },
    {
        "name": "Khmer",
        "key": 74,
        "gg_ads_key": 1085
    },
    {
        "name": "Tajik",
        "key": 89,
        "gg_ads_key": 1132
    },
    {
        "name": "Arabic",
        "key": 28,
        "gg_ads_key": 1019
    },
    {
        "name": "Hebrew",
        "key": 29,
        "gg_ads_key": 1027
    },
    {
        "name": "Urdu",
        "key": 90,
        "gg_ads_key": 1041
    },
    {
        "name": "Persian",
        "key": 60,
        "gg_ads_key": 1064
    },
    {
        "name": "Pashto",
        "key": 85,
        "gg_ads_key": null
    },
    {
        "name": "Sinhala",
        "key": 86,
        "gg_ads_key": null
    },
    {
        "name": "Japanese (Kansai)",
        "key": 70,
        "gg_ads_key": 1005
    },
    {
        "name": "English (All)",
        "key": 1001,
        "gg_ads_key": 1000
    },
    {
        "name": "Spanish (All)",
        "key": 1002,
        "gg_ads_key": 1003
    },
    {
        "name": "French (All)",
        "key": 1003,
        "gg_ads_key": 1002
    },
    {
        "name": "Chinese (All)",
        "key": 1004,
        "gg_ads_key": 1008
    },
    {
        "name": "Portuguese (All)",
        "key": 1005,
        "gg_ads_key": 1005
    }
];