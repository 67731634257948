import React from 'react';
import ReactECharts from 'echarts-for-react';


interface GeoLiftPowerCurve {
    data: any
}

const GeoLiftPowerCurve: React.FC<GeoLiftPowerCurve> = ({ data }) => {

    const option = {
        title: {
            text: 'GeoLift Power Curve',
            left: 'center',
            textStyle: {
                fontSize: 18,
                fontWeight: 'bold'
            },
            subtextStyle: {
                fontSize: 14,
                fontStyle: 'italic'
            }
        },
        grid: {
            top: 80,
            bottom: 60,
            left: 80,
            right: 80,
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                name: 'Effect Size',
                nameTextStyle: {
                    padding: [5, 0, 0, 0],
                    fontSize: 14
                },
                data: Object.values(data.EffectSize).map((value: any) => `${(value * 100).toFixed(0)}%`), // Convert to percentage format
                axisLabel: {
                    interval: 4, // Show every 5th label (0-based index)
                    rotate: 45 // Rotate labels at 45 degrees
                }
            },
            {
                type: 'category',
                name: 'Investment',
                nameTextStyle: {
                    padding: [5, 0, 0, 0],
                    fontSize: 14
                },
                data: Object.values(data.Investment).map((value: any) => `$${value.toFixed(0)}`), // Format to two decimals
                position: 'top',
                axisLabel: {
                    interval: 4, // Show every 5th label
                    rotate: 45 // Rotate labels at 45 degrees
                }
            }
        ],
        yAxis: {
            type: 'value',
            name: 'Power',
            nameLocation: 'center',
            nameGap: 40, // Adds padding to move the label away from the axis
            nameTextStyle: {
                fontSize: 14
            },
            min: 0,
            max: 100,
            axisLabel: {
                formatter: '{value} %'
            }
        },
        series: [
            {
                name: 'Actual Values',
                type: 'line',
                data: Object.values(data.Power).map((value: any) => {
                    const percentageValue = value * 100;
                    return percentageValue < 80 ? 0 : percentageValue.toFixed(2); // Apply threshold and format to two decimals
                }),
                lineStyle: {
                    width: 2
                },
                itemStyle: {
                    color: 'blue'
                }
            }
        ],
        legend: {
            data: ['Actual Values'],
            bottom: 10,
            textStyle: {
                fontSize: 12
            }
        },
        tooltip: {
            trigger: 'axis',
            formatter: (params: any) => {
                const effectSize = params[0].axisValue;
                const power = params[0].data;
                const investmentArray: any = Object.values(data.Investment);
                const investment = investmentArray[params[0].dataIndex].toFixed(2) as string;
                return `<b>Effect Size: ${effectSize}</b><br/>Power: ${power}%<br/>Investment: $${investment}`;
            }
        }
    };

    return <ReactECharts option={option} style={{ height: 400, width: '100%', padding: '20px' }} />;
};

export default GeoLiftPowerCurve;
