import React, { useState } from 'react';
import PieChartECharts from './PieChart';
import { formatCurrency } from '../utils/format';

type TableProps = {
  scenarioData: {
    [scenario: string]: {
      variables: { [variable: string]: string }; // Variables and their values
      yearlySpendChartData: number[]; // Data for the pie chart
      predictedSales: number; // Data for the pie chart
      labels: string[]; // Labels for the pie chart
    };
  };
  callback: (data: any) => void;
  isLoading: boolean;
};

const ScenarioTable: React.FC<TableProps> = ({ scenarioData, callback, isLoading = false }) => {
  const scenarios = Object.keys(scenarioData); // Dynamically get scenarios from scenarioData

  // Function to calculate the sum of all values in yearlySpendChartData
  const calculateSum = (yearlySpendChartData: number[]) => {
    return yearlySpendChartData.reduce((acc, value) => acc + value, 0);
  };

  // State to hold input values for each scenario and variable combination
  const [inputs, setInputs] = useState<{ [key: string]: string }>(() => {
    const initialInputs: { [key: string]: string } = {};
    scenarios.forEach((scenario) => {
      const sum = calculateSum(scenarioData[scenario].yearlySpendChartData) / 100; // Calculate the sum

      // Loop through variables and find the correct index in labels
      Object.keys(scenarioData[scenario].variables).forEach((variable) => {
        // Find the correct index for this variable in the labels array
        const index = scenarioData[scenario].labels.findIndex(label => label === variable);

        // If the variable value is '0', use the corresponding value from yearlySpendChartData divided by the sum as the default
        if (index !== -1) { // Check if the variable exists in the labels array
          initialInputs[`${scenario}-${variable}`] =
            scenarioData[scenario].variables[variable] == '0'
              ? Math.floor(scenarioData[scenario].yearlySpendChartData[index] / sum).toFixed(2)
              : scenarioData[scenario].variables[variable];
        } else {
          initialInputs[`${scenario}-${variable}`] = Number.parseFloat(scenarioData[scenario].variables[variable]).toFixed(0);
        }
      });
    });
    return initialInputs;
  });

  const [errors, setErrors] = useState<string[]>([]);

  const handleInputChange = (scenario: string, variable: string, value: string) => {
    setInputs({
      ...inputs,
      [`${scenario}-${variable}`]: value,
    });
  };

  // Validation function to check if all inputs are filled and are valid numbers
  const validateInputs = (): boolean => {
    const newErrors: string[] = [];
    for (const key in inputs) {
      const value = inputs[key];

      // Check if the value is empty
      if (!value) {
        newErrors.push(`The value for ${key} is required.`);
      }

      // Check if the value is not a number
      if (value && isNaN(Number(value))) {
        newErrors.push(`The value for ${key} must be a valid number.`);
      }
    }
    setErrors(newErrors);
    return newErrors.length === 0;
  };

  // Function to transform inputs into an object grouped by scenarios
  const transformData = () => {
    const transformedData: { [scenario: string]: { [variable: string]: string } } = {};

    scenarios.forEach((scenario) => {
      transformedData[scenario] = {};
      const sum = calculateSum(scenarioData[scenario].yearlySpendChartData); // Calculate sum again
      Object.keys(scenarioData[scenario].variables).forEach((variable) => {
        // Find the correct index for this variable in the labels array
        const index = scenarioData[scenario].labels.findIndex(label => label === variable);
        const inputKey = `${scenario}-${variable}`;
        const inputValue = inputs[inputKey];

        // If the input value is zero, use the value from yearlySpendChartData divided by the sum
        if (index !== -1 && Number(inputValue) === 0) {
          transformedData[scenario][variable] = (scenarioData[scenario].yearlySpendChartData[index] / sum).toFixed(2);
        } else {
          transformedData[scenario][variable] = inputValue;
        }
      });
    });

    return transformedData;
  };

  // Function to handle form submission
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateInputs()) {
      // If validation passes, transform the data
      const transformedData = transformData();
      console.log('Transformed Data:', transformedData);
      callback(transformedData);
    }
  };

  return (
    <form className='w-full' onSubmit={handleSubmit}>
      <div className="overflow-x-auto">
        <table className="table w-full border border-collapse border-gray-300">
          <thead>
            <tr className="border border-gray-300">
              <th className="border border-gray-300 p-2">Variable</th>
              {scenarios.map((scenario) => (
                <th key={scenario} className="border border-gray-300 p-2">{scenario}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.keys(scenarioData[scenarios[0]].variables).map((variable) => (
              <tr key={variable} className="border border-gray-300">
                <td className="border border-gray-300 p-2">{variable
                  .split('_')
                  .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(' ')}</td>
                {scenarios.map((scenario) => (
                  <td key={scenario} className="border border-gray-300 p-2">
                    <input
                      type="text"
                      className="w-full p-1"
                      style={{
                        border: 'none',
                        background: 'transparent',
                        outline: 'none',
                      }}
                      value={inputs[`${scenario}-${variable}`]}
                      onChange={(e) => handleInputChange(scenario, variable, e.target.value)}
                    />
                  </td>
                ))}
              </tr>
            ))}
            {/* Row to display pie charts for each scenario */}
            <tr>
              <td className="border border-gray-300 p-2">Predicted Response Variable</td>
              {scenarios.map((scenario) => (
                <td key={scenario} className="border border-gray-300 p-2">
                  {formatCurrency(scenarioData[scenario].predictedSales)}
                </td>
              ))}
            </tr>
            <tr>
              <td className="border border-gray-300 p-2">Yearly Optimized Spend</td>
              {scenarios.map((scenario) => (
                <td key={scenario} className="border border-gray-300 p-2">
                  {/* Pass the data and labels to the PieChartECharts component */}
                  <PieChartECharts
                    data={scenarioData[scenario].yearlySpendChartData} // Numeric data for the pie chart
                    labels={scenarioData[scenario].labels} // Labels to be displayed in the pie chart
                    showOutline={false}
                  />
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>

      {/* Error Messages */}
      {errors.length > 0 && (
        <div className="mt-4 text-red-600">
          <ul>
            {errors.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        </div>
      )}

      <div className="mt-4 text-center">
        <button type="submit" className="btn btn-primary w-1/3" disabled={isLoading}>
          {isLoading && (
            <span className="loading loading-spinner loading-xs"></span>
          )}
          Recalculate</button>
      </div>
    </form>
  );
};

export default ScenarioTable;
